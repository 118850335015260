<a
  class="msa-text tw-group tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-3 tw-transition-colors tw-duration-200 tw-ease-in-out d:tw-gap-6"
  [class]="[colorStyling(), 'hover:' + hoverStyling()]"
  [attr.href]="externalLink() ? externalLink() : null"
>
  <span>{{ label() | translate }}</span>
  @if (showArrow()) {
    <mat-icon
      class="!tw-size-21 tw-flex-shrink-0 tw-transform tw-transition-transform tw-duration-200 tw-ease-in-out d:!tw-size-24 group-hover:d:tw-translate-x-9"
      svgIcon="arrow-east"
    />
  }
</a>
