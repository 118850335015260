import {KeyValuePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Output, inject, input} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {HostControlDirective} from '@shared/shared-module/directives/host-control.directive';

@Component({
  selector: 'msa-checkbox',
  standalone: true,
  imports: [KeyValuePipe, TranslateModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './msa-checkbox.component.html',
  hostDirectives: [HostControlDirective]
})
export class MsaCheckboxComponent {
  public id = input.required<string>();
  public label = input<string>();

  @Output() checked = new EventEmitter<boolean>();

  public hostControlDirective = inject(HostControlDirective);
}
