import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { MsaPortalConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { PopupsRestService } from './api/popups.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    PopupsRestService ]
})
export class MsaPortalApiModule {
    public static forRoot(configurationFactory: () => MsaPortalConfiguration): ModuleWithProviders<MsaPortalApiModule> {
        return {
            ngModule: MsaPortalApiModule,
            providers: [ { provide: MsaPortalConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: MsaPortalApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('MsaPortalApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
