import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {PopupsService} from '@shared/shared-module/services/popup/popups.service';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {PopUpDto, PopUpDtoStatusEnum, PopUpDtoTypeEnum} from '@shared/core/api/generated/msa-portal';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {NgOptimizedImage} from '@angular/common';
import {QRCodeModule} from 'angularx-qrcode';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MsaDialogComponent,
    SafeTranslatePipe,
    MsaCheckboxComponent,
    MsaContentNoticeComponent,
    MsaButtonComponent,
    NgOptimizedImage,
    QRCodeModule
  ],
  providers: [SafeTranslatePipe],
  selector: 'msa-wallet-popup',
  standalone: true,
  templateUrl: './wallet-popup.component.html'
})
export class WalletPopupComponent {
  private dismissForever = false;
  private popupsService = inject(PopupsService);
  private dialogRef = inject(MatDialogRef);
  private safeTranslatePipe = inject(SafeTranslatePipe);

  dismissWalletPopup() {
    const popupDto: PopUpDto = {
      type: PopUpDtoTypeEnum.WalletInstallation,
      status: this.dismissForever ? PopUpDtoStatusEnum.Inactive : PopUpDtoStatusEnum.Dismissed
    };
    this.popupsService.dismissPopup(popupDto).subscribe(() => this.dialogRef.close());
  }

  openWalletAppLink() {
    const popupDto: PopUpDto = {
      type: PopUpDtoTypeEnum.WalletInstallation,
      status: PopUpDtoStatusEnum.Dismissed
    };
    this.popupsService.dismissPopup(popupDto).subscribe(() => this.dialogRef.close());
    window.open(this.safeTranslatePipe.transform('i18n.WalletPopup.link'), '_blank');
  }

  toggleDismissForever() {
    this.dismissForever = !this.dismissForever;
  }
}
