import {CanActivateFn} from '@angular/router';
import {inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PopupsService} from '@shared/shared-module/services/popup/popups.service';
import {PopUpDtoStatusEnum, PopUpDtoTypeEnum} from '@shared/core/api/generated/msa-portal';
import {WalletPopupComponent} from '@shared/shared-module/components/popups/wallet-app/wallet-popup.component';
import {Observable, of, startWith, switchMap, take} from 'rxjs';

export const popupsGuard: CanActivateFn = () => {
  const popupsService = inject(PopupsService);
  const matDialog = inject(MatDialog);
  const activeStatuses = new Set([PopUpDtoStatusEnum.Mandatory, PopUpDtoStatusEnum.Dismissible]);

  const popupConfigs = [
    {
      type: PopUpDtoTypeEnum.WalletInstallation,
      component: WalletPopupComponent
    }
    // Add other popups in order of display
  ];

  const handlePopups = (): Observable<boolean> => {
    return popupsService.popups$.pipe(
      startWith(popupsService.popups()),
      take(1),
      switchMap(popups => {
        const popupToShow = popupConfigs.find(({type}) =>
          popups.some(popup => popup.type === type && activeStatuses.has(popup.status!))
        );
        if (popupToShow) {
          return matDialog
            .open(popupToShow.component, {disableClose: true})
            .afterClosed()
            .pipe(switchMap(() => handlePopups()));
        } else {
          return of(true);
        }
      })
    );
  };
  return handlePopups();
};
