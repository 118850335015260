import {ChangeDetectionStrategy, Component, DestroyRef, effect, inject, OnInit} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AppStateSelectors} from '@shared/shared-module/stores/selectors/app.state.selectors';
import {SwissPassDto} from '../../core/api/generated/msa-person-data';
import {HttpStatusCode} from '@angular/common/http';
import {ResetStatusCode, UpdateSwissPass} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {MsaTextInputComponent} from '@shared/shared-module/components/msa-text-input/msa-text-input.component';
import {MsaArrowLinkComponent} from '@shared/shared-module/components/msa-arrow-link/msa-arrow-link.component';
import {NgOptimizedImage} from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MsaDialogComponent,
    MsaContentNoticeComponent,
    MsaTextInputComponent,
    ReactiveFormsModule,
    SafeTranslatePipe,
    MsaArrowLinkComponent,
    NgOptimizedImage
  ],
  selector: 'msa-connect-swiss-pass-dialog',
  standalone: true,
  templateUrl: './connect-swiss-pass-dialog.component.html'
})
export class ConnectSwissPassDialogComponent implements OnInit {
  private store: Store = inject(Store);
  private destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly HttpStatusCode = HttpStatusCode;

  currentLanguage = readStoreSignal(AppStateSelectors.slices.language);

  errorMessage = readStoreSignal(PersonDataStateSelectors.slices.statusCode);

  customerNumberForm = new FormControl('', [Validators.required, Validators.maxLength(13)]);

  constructor() {
    effect(() => {
      if (this.errorMessage() === HttpStatusCode.UnprocessableEntity) {
        this.customerNumberForm.setErrors({
          invalidField: {text: 'i18n.person-data.dialogs.connect-swiss-pass.customer-number-content-error'}
        });
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(new ResetStatusCode());
  }

  connectSwissPass($event: DialogConfirmEvent) {
    const swissPassDto: SwissPassDto = {
      ckm: this.customerNumberForm.value ?? ''
    };
    this.store.dispatch(new UpdateSwissPass(swissPassDto)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: $event.resolve,
      error: $event.reject
    });
  }
}
