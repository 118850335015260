<fieldset class="tw-align-center tw-flex tw-flex-col">
  <div class="tw-flex tw-flex-row tw-content-center tw-items-center tw-gap-9 tw-text-black-light-5">
    <div class="tw-grid tw-place-items-center">
      <input
        class="tw-peer tw-col-start-1 tw-row-start-1 tw-h-18 tw-w-18 tw-shrink-0 tw-appearance-none tw-rounded-full tw-border-2 tw-border-black-light-5 tw-bg-transparent hover:tw-border-black-light-7 disabled:tw-border-black-light-1 disabled:checked:tw-bg-black-light-2 d:tw-h-21 d:tw-w-21 [&.ng-invalid.ng-touched]:tw-border-vermillion-light-2"
        [id]="id()"
        [value]="value()"
        [name]="name()"
        [formControl]="hostControlDirective.control()"
        (select)="selected.emit(value())"
        type="radio"
      />

      <div
        class="tw-col-start-1 tw-row-start-1 tw-hidden tw-h-[11.14px] tw-w-[11.14px] tw-rounded-full tw-bg-white peer-checked:tw-block peer-[&.ng-invalid.ng-touched]:tw-bg-vermillion-light-2 d:tw-h-[13px] d:tw-w-[13px]"
      ></div>
    </div>

    <div
      class="msa-text peer-checked:tw-text-white peer-hover:tw-text-black-light-7 peer-disabled:tw-text-black-light-3 peer-[&.ng-invalid.ng-touched]:tw-text-vermillion-light-2"
    >
      @if (label(); as labelText) {
        <label>
          {{ labelText | translate }}
        </label>
      }
      <ng-content />
    </div>
  </div>

  @if (hostControlDirective.control().touched) {
    @for (error of hostControlDirective.control().errors | keyvalue; track error) {
      <p class="msa-text tw-mt-12 tw-text-vermillion-light-2">{{ error.value.text | translate: error.value.params }}</p>
    }
  }
</fieldset>
