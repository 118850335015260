import {ComponentType} from '@angular/cdk/overlay';
import {ChangeDetectionStrategy, Component, inject, input} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MsaTooltipComponent} from '@shared/shared-module/components/msa-tooltip/msa-tooltip.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';

export interface MenuItem {
  component: ComponentType<unknown>;
  menuItemTitle: TranslationString;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MsaTooltipComponent,
    SafeTranslatePipe,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MsaTooltipComponent,
    MsaContentNoticeComponent
  ],
  selector: 'msa-card',
  standalone: true,
  templateUrl: './msa-card.component.html'
})
export class MsaCardComponent {
  private matDialog = inject(MatDialog);

  cardTitle = input<TranslationString>('');
  footNote = input<TranslationString>('');
  menuItems = input<Array<MenuItem>>();
  noPadding = input<boolean>(false);
  tooltipTitle = input<TranslationString>('');
  tooltipContent = input<TranslationString>('');
  activeCard = input<boolean>(true);

  openDialog(menuItem: ComponentType<unknown>) {
    this.matDialog.open(menuItem);
  }
}
