import {DestroyRef, inject, Injectable, signal} from '@angular/core';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {Observable, tap} from 'rxjs';
import {PopUpDto, PopupsRestService} from '@shared/core/api/generated/msa-portal';

@Injectable({
  providedIn: 'root'
})
export class PopupsService {
  private popupsRestService = inject(PopupsRestService);
  private destroyRef = inject(DestroyRef);

  public popups = signal<Array<PopUpDto>>([]);
  public popups$ = toObservable(this.popups);

  constructor() {
    this.fetchPopups();
  }

  fetchPopups() {
    this.popupsRestService
      .getPopups()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(body => this.popups.set(body));
  }

  dismissPopup(popup: PopUpDto): Observable<PopUpDto> {
    return this.popupsRestService.dismissPopup(popup).pipe(
      tap(result => {
        this.popups.update(currentPopups => currentPopups.filter(p => p.type !== result.type));
      })
    );
  }
}
