<fieldset class="tw-flex tw-flex-col tw-gap-3 d:tw-gap-y-6" xmlns="http://www.w3.org/1999/html">
  @if (label()) {
    <label
      class="msa-text"
      [for]="id()"
      [class.tw-text-black-light-3]="hcd.control().disabled"
      [appMarkRequired]="hcd.control()"
    >
      {{ label()! | translate }}
    </label>
  }
  <div class="tw-relative">
    <input
      class="msa-text tw-w-full tw-border tw-border-black-light-4 tw-bg-transparent tw-px-15 tw-py-12 tw-text-white tw-outline-none placeholder:tw-text-black-light-7 d:tw-px-18 d:tw-py-15 {{
        inputClasses()
      }}"
      [class.!tw-text-vermillion-light-2]="hcd.control().touched && hcd.control().invalid"
      [class.!tw-border-vermillion-light-2]="hcd.control().touched && hcd.control().invalid"
      [class.placeholder:!tw-text-vermillion-light-2]="hcd.control().touched && hcd.control().invalid"
      [class.!tw-text-black-light-3]="hcd.control().disabled"
      [class.!tw-border-black-light-2]="hcd.control().disabled"
      [class.placeholder:!tw-text-black-light-3]="hcd.control().disabled"
      [class.!tw-border-white]="isActive()"
      [id]="id()"
      [attr.name]="name()"
      [formControl]="hcd.control()"
      [attr.placeholder]="placeholder() ? (placeholder()! | translate) : null"
      [mask]="ngxMask()"
      [dropSpecialCharacters]="ngxDropSpecialCharacters()"
      [attr.maxLength]="maxLength()"
      [attr.minLength]="minLength()"
      [maxLength]="maxLength()"
      [attr.data-cy]="dataCy()"
      (blur)="onBlur()"
      (focus)="onFocus()"
      type="text"
    />
    <ng-content select="[suffixContainer]" />
  </div>
  @if (hint()) {
    <p class="msa-text-small tw-text-black-light-5" [class.tw-text-black-light-3]="hcd.control().disabled">
      {{ hint()! | translate }}
    </p>
  }
  <ng-content select="[hintContainer]" />

  @if (!hideValidationErrors() && hcd.control().touched && hcd.control().errors; as errors) {
    @for (error of errors | keyvalue; track error) {
      @if (error.value.text) {
        <p class="msa-text-small tw-text-vermillion-light-2">
          {{ error.value.text | translate: error.value.params }}
        </p>
      }
    }
  }
  <ng-content select="[errorContainer]" />
</fieldset>
